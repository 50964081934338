import React from 'react'
import halloween2024 from '../../../../images/Halloween2024.jpg'
import { makeStyles } from '@material-ui/core';
import halloweenBackground from '../../../../images/halloweenBackground2023.jpg'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: '10%',
        // marginRight: '10%',
        width: '100%',
        backgroundImage: `url(${halloweenBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.9,
        overflow: 'hidden',
        position: 'relative',
        color: '#fff',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaVideoSlide: {
        width: '100%',
        borderRadius: 20,
    },
    media: {
        width: '40%',
        borderRadius: 20,
        border: '5px solid orange'
    },
    marginLeft: {
        marginLeft: '10%',
    }
}));

export default function Halloween2024() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header>
                <h2 className={classes.textCentered}>☾⋆⁺₊👻🎃🦇´₊⁺~ Halloween 2024 ☾⋆⁺₊🎃👻🦇´₊⁺~</h2>
            </header>
            <br />

            <div className={classes.textCentered}>
                <img className={classes.media} src={halloween2024} alt="Halloween 2024" />
            </div>

            <br />

            <p className={classes.marginLeft}>Regards,<br />
                Eleanor Lester<br />
                HR Co-Ordinator | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}
