import React, { useState, useContext } from 'react'
import { makeStyles, TextField, MenuItem, Button, Grid, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Container } from '@material-ui/core';
import { AuthContext } from '../../../../contexts/AuthContext';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import Loading from '../../Loading';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    buttonRight: {
        float: 'right',
        marginTop: '35px',
        marginRight: '15%',
        // marginBottom: '24px',
    },
    margin15: {
        margin: '15px',
    },
    filePicker: {
        float: 'left',
        marginLeft: '16%',
        marginTop: '35px',
    },
    input: {
        display: 'none',
    },
    marginRight: {
        marginRight: '15px'
    },
    groupSelect: {
        width: '100%',
        // height: 200,
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: 5,
        margin: 5,
    },
    checkboxLabel: {
        margin: 5,
        width: 180
    }
}));

const TicketFormIT = (props) => {
    const classes = useStyles();

    const { createTicket } = useContext(DataContext);
    const { username, email, displayName } = useContext(AuthContext);

    const [ticketForm, setTicketForm] = useState({
        userEmail: '',
        userFullName: '',
        username: '',
        TicketType: '',
        Platform: '',
        Site: '',
        Department: '',
        Process: '',
        issueCategory: '',
        issueNavErrorNo: '',
        issueArea: '',
        issueDescription: '',
        requestCategory: '',
        requestDescription: '',
        moreThanOnePlatform: [],
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
    });
    const [checkedState, setCheckedState] = useState({
        Returntek: false,
        VirtuRL: false,
        DataDEAD: false,
        TotalRMA: false,
        Nav: false,
        WisetekTools: false,
        EmailOutlook: false,
        Phone: false,
        Website: false,
        Fileserver: false,
        McAfee: false,
        Jet: false,
        Hardware: false,
        ARCHIE: false,
        Domain: false,
        VPN: false,
        ISOXpress: false,
        Network: false,
    });

    const [isLoadingAttachment, setIsLoadingAttachment] = useState(false)

    const handleChangeChecked = (e) => {
        setCheckedState({
            ...checkedState,
            [e.target.name]: e.target.checked
        })
        // var allOptions = ['Returntek','VirtuRL','DataDEAD','TotalRMA','Nav','WisetekTools', 'EmailOutlook','Phone', 'Website', 'Fileserver', 'McAfee','Jet','Hardware', 'ARCHIE','Domain','VPN','ISOXpress']
        // var newPlatforms = []
    }
    function clearCheckedState() {
        setCheckedState({
            Returntek: false,
            VirtuRL: false,
            DataDEAD: false,
            TotalRMA: false,
            Nav: false,
            WisetekTools: false,
            EmailOutlook: false,
            Phone: false,
            Website: false,
            Fileserver: false,
            McAfee: false,
            Jet: false,
            Hardware: false,
            ARCHIE: false,
            Domain: false,
            VPN: false,
            ISOXpress: false,
            Network: false,
        })
    }
    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const ticketTypeIT = [
        { value: '', label: '' },
        { value: 'Issue / Error', label: 'Issue / Error' },
        { value: 'Change Request', label: 'Change Request' },
    ];
    const issueCategory = [
        { value: '', label: '' },
        { value: 'Access Failure', label: 'Access Failure' },
        { value: 'Hardware Failure', label: 'Hardware Failure' },
        { value: 'System Error', label: 'System Error' },
        { value: 'Reporting Error', label: 'Reporting Error' },
    ];
    const requestCategory = [
        { value: '', label: '' },
        { value: 'Access Needed', label: 'Access Needed' },
        { value: 'New Report Needed', label: 'New Report Needed' },
        { value: 'System Change Request', label: 'System Change Request' },
        { value: 'Hardware Required', label: 'Hardware Required' },
        { value: 'Software Required', label: 'Software Required' },
        { value: 'New Hire', label: 'New Hire' },
        { value: 'Termination of employment', label: 'Termination of employment' },
    ];
    const platform = [
        { value: 'More then one selected:', label: 'More then one from the list...' },
        { value: 'Returntek', label: 'Returntek' },
        { value: 'VirtuRL', label: 'VirtuRL' },
        { value: 'DataDEAD', label: 'DataDEAD' },
        { value: 'TotalRMA', label: 'TotalRMA' },
        { value: 'Nav', label: 'Nav' },
        { value: 'Wisetek Tools', label: 'Wisetek Tools' },
        { value: 'Email-Outlook', label: 'Email-Outlook' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Website', label: 'Website' },
        { value: 'Fileserver', label: 'Fileserver' },
        { value: 'McAfee', label: 'McAfee' },
        { value: 'Jet', label: 'Jet' },
        { value: 'Hardware', label: 'Hardware' },
        { value: 'ARCHIE', label: 'ARCHIE' },
        { value: 'Domain', label: 'Domain' },
        { value: 'VPN', label: 'VPN' },
        { value: 'ISOXpress', label: 'ISOXpress' },
        { value: 'Network', label: 'Network' },
    ];
    const site = [
        { value: 'Kilbarry', label: 'Kilbarry' },
        { value: 'K4 / K5', label: 'K4 / K5' },
        { value: 'Carrigtwohill', label: 'Carrigtwohill' },
        { value: 'UK', label: 'UK' },
        { value: 'Thailand', label: 'Thailand' },
        { value: 'MD - Maryland', label: 'MD - Maryland' },
        { value: 'TX - Texas', label: 'TX - Texas' },
        { value: 'VA - Virginia', label: 'VA - Virginia' },
        { value: 'SAC - Sacramento', label: 'SAC - Sacramento' },
        { value: 'MA - Massachusetts', label: 'MA - Massachusetts' },
        { value: 'Dubai', label: 'Dubai' },
        { value: 'All sites', label: 'All Sites' },
    ];
    const department = [
        { value: '', label: '' },
        { value: 'Customer Service', label: 'Customer Service' },
        { value: 'Engineering', label: 'Engineering' },
        { value: 'Finance', label: 'Finance' },
        { value: 'HR', label: 'HR' },
        { value: 'IT', label: 'IT' },
        { value: 'Logistics', label: 'Logistics' },
        { value: 'Operations', label: 'Operations' },
        { value: 'Procurement', label: 'Procurement' },
        { value: 'Quality', label: 'Quality' },
        { value: 'R&D', label: 'R&D' },
        { value: 'Sales', label: 'Sales' },
        { value: 'TRMA', label: 'TRMA' },
    ];
    const process = [
        { value: '', label: '' },
        { value: 'ITAD', label: 'ITAD' },
        { value: 'AVR', label: 'AVR' },
        { value: 'Forcepoint', label: 'Forcepoint' },
        { value: 'LB', label: 'LB' },
        // { value: 'RLO', label: 'RLO' },
        { value: 'Dexgreen', label: 'Dexgreen' },
        { value: 'Protek', label: 'Protek' },
        { value: 'Onsite Service', label: 'Onsite Service' },
        { value: 'All', label: 'All' },
    ];
    const issueArea = [
        { value: '', label: '' },
        { value: 'Basic Audit', label: 'Basic Audit' },
        { value: 'Receiving', label: 'Receiving' },
        { value: 'Tech Audit', label: 'Tech Audit' }, //changed from tech to Tech Audit
        { value: 'Verify', label: 'Verify' },
        { value: 'CTO', label: 'CTO' },
        { value: 'RLO', label: 'RLO' },
        { value: 'Erasure', label: 'Erasure' },
        { value: 'Sort', label: 'Sort' },
        { value: 'Shipping', label: 'Shipping' },
        { value: 'Sales Orders', label: 'Sales Orders' },
        { value: 'Purchase Orders', label: 'Purchase Orders' },
        { value: 'MRP', label: 'MRP' },
        { value: 'Reporting', label: 'Reporting' },
        { value: 'Picco', label: 'Picco' },
        // { value: 'VPN Access', label: 'VPN Access' },
        // { value: 'NAV Access', label: 'NAV Access' },
        // { value: 'Network Access', label: 'Network Access' },
    ];

    const handleChangeTicketTypeIT = (e) => {

        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.value,
            userEmail: email, //adding email to data when it will be send to function createTicket
            userFullName: displayName, //adding FullName to data when it will be send to function createTicket
            username: username, //adding username to data when it will be send to function createTicket
            // reset other fields
            Platform: '',
            Site: '',
            Department: '',
            Process: '',
            issueCategory: '',
            issueNavErrorNo: '',
            issueArea: '',
            issueDescription: '',
            requestCategory: '',
            requestDescription: '',
            moreThanOnePlatform: [],
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        })
        clearCheckedState()
    }
    const handleChange = (e) => {
        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.value,
        })
    }
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const disableSubmitBtn = () => {
        setIsLoadingAttachment(true)
    }
    const handleChangeFilePicker = (e) => {

        var { target: { files } } = e

        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...ticketForm.files];
        var filesToStore = [...ticketForm.filesToStoreInDB];
        [...files].forEach(file => {
            console.log(file.type)
            if (file.type.toLowerCase().includes("audio/") ||
                file.type.toLowerCase().includes("video/") ||
                file.type.toLowerCase().includes("image/") ||
                file.type.toLowerCase().includes("application/pdf") ||
                file.type.toLowerCase().includes("application/vnd") || //for all office documents
                file.type.toLowerCase().includes("text/")) { //text/csv text/hml
                // storage
                //     .ref('ticketAttachments/' + '01Hk2AQqOOoHjy2LBXql/' + file.name)
                //     .put(file)
                // console.log(file)
                filesToStore.push(file)
                var convertResult = ''
                calculatedSize += file.size

                toBase64(file)
                    .then(result => {
                        convertResult = result
                        filesToSend.push({
                            content: convertResult,
                            filename: file.name,
                            type: file.type,
                            disposition: "attachment"
                        })
                        // console.log(calculatedSize)
                        if (ticketForm.filesSize >= maxSize || calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                            setIsLoadingAttachment(false)
                        }
                        else {
                            if (ticketForm.filesSize + calculatedSize >= maxSize) {
                                setSnackbar({
                                    open: true,
                                    severity: 'warning',
                                    message: 'Selected files are too big to attach.',
                                })
                                setIsLoadingAttachment(false)
                            }
                            else {
                                setTicketForm({
                                    ...ticketForm,
                                    files: filesToSend,
                                    filesSize: ticketForm.filesSize + calculatedSize,
                                    filesToStoreInDB: filesToStore,
                                });
                                // console.log(filesToSend)
                                setIsLoadingAttachment(false)
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        setIsLoadingAttachment(false)
                    })
            } else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'File type not supported, please attach supported file: "audio/*, video/*, image/*, .pdf, .xlsx, .xls, .csv"',
                });
                setIsLoadingAttachment(false)
            }
        })
        e.target.value = null // to allow selecting the same file over and over

    }
    const handleChangeFilePickerRemove = (e) => {
        setTicketForm({
            ...ticketForm,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
    }

    const clearStateAndFields = () => {

        setTicketForm({
            userEmail: '',
            userFullName: '',
            username: '',
            TicketType: '',
            Platform: '',
            Site: '',
            Department: '',
            Process: '',
            issueCategory: '',
            issueNavErrorNo: '',
            issueArea: '',
            issueDescription: '',
            requestCategory: '',
            requestDescription: '',
            moreThanOnePlatform: [],
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
        clearCheckedState()
        // get form and reset all inputs
        console.log("resetting form");
    }
    var isSubmitting = false
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isSubmitting) {
            isSubmitting = true
            var newPlatforms = []
            if (checkedState.Returntek) newPlatforms.push('Returntek')
            if (checkedState.VirtuRL) newPlatforms.push('VirtuRL')
            if (checkedState.DataDEAD) newPlatforms.push('DataDEAD')
            if (checkedState.TotalRMA) newPlatforms.push('TotalRMA')
            if (checkedState.Nav) newPlatforms.push('Nav')
            if (checkedState.WisetekTools) newPlatforms.push('Wisetek Tools')
            if (checkedState.EmailOutlook) newPlatforms.push('Email-Outlook')
            if (checkedState.Phone) newPlatforms.push('Phone')
            if (checkedState.Website) newPlatforms.push('Website')
            if (checkedState.Fileserver) newPlatforms.push('Fileserver')
            if (checkedState.McAfee) newPlatforms.push('McAfee')
            if (checkedState.Jet) newPlatforms.push('Jet')
            if (checkedState.Hardware) newPlatforms.push('Hardware')
            if (checkedState.ARCHIE) newPlatforms.push('ARCHIE')
            if (checkedState.Domain) newPlatforms.push('Domain')
            if (checkedState.VPN) newPlatforms.push('VPN')
            if (checkedState.ISOXpress) newPlatforms.push('ISOXpress')
            if (checkedState.Network) newPlatforms.push('Network')
            ticketForm.moreThanOnePlatform = newPlatforms

            console.log('Trying to submit form...', ticketForm.moreThanOnePlatform);
            if (ticketForm.TicketType === 'Issue / Error') {

                if (ticketForm.issueCategory !== '' &&
                    ticketForm.Platform !== '' &&
                    ticketForm.Site !== '' &&
                    ticketForm.Department !== '' &&
                    ticketForm.Process !== '' &&
                    ticketForm.issueArea !== '' &&
                    ticketForm.issueDescription !== '') {
                    createTicket(ticketForm)
                        .then(success => {
                            console.log('added new issue ticket', success)
                            //display success
                            setSnackbar({
                                open: true,
                                severity: 'success',
                                message: success.message,
                            });
                            clearStateAndFields();
                            isSubmitting = false
                        })
                        .catch(error => {
                            console.log(error)
                            //display error
                            setSnackbar({
                                open: true,
                                severity: 'error',
                                message: error.message,
                            });
                            isSubmitting = false
                        })
                }
                else {
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: '* Please select all required fields and add description.',
                    })
                    isSubmitting = false
                }
            }
            else {
                if (ticketForm.requestCategory !== '' &&
                    ticketForm.Platform !== '' &&
                    ticketForm.Site !== '' &&
                    ticketForm.Department !== '' &&
                    ticketForm.Process !== '' &&
                    ticketForm.requestDescription !== '') {// change to request 
                    createTicket(ticketForm)
                        .then(success => {
                            console.log('added new change request ticket')
                            //display success
                            setSnackbar({
                                open: true,
                                severity: 'success',
                                message: success.message,
                            });
                            clearStateAndFields();
                            isSubmitting = false
                        })
                        .catch(error => {
                            console.log(error)
                            //display error
                            setSnackbar({
                                open: true,
                                severity: 'error',
                                message: error.message,
                            });
                            isSubmitting = false
                        })
                }
                else {
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: '* Please select all required fields and add description.',
                    })
                    isSubmitting = false
                }
            }
        }
    }

    return (
        <div>
            <form id="ticketFormIT" onSubmit={handleSubmit}>
                <h3 className={classes.center}>Create New IT Ticket</h3>

                <Container maxwidth="xs">
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} >
                        <Grid container item xs={12} sm={4} spacing={1} >

                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                fullWidth
                                required
                                id="TicketType"
                                label="IT Ticket Type"
                                name="TicketType"
                                className={classes.margin15}
                                inputProps={{ maxLength: 50 }}
                                value={ticketForm.TicketType}
                                // InputLabelProps={{ shrink: true }}
                                onChange={handleChangeTicketTypeIT}>
                                {ticketTypeIT.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Container>

                {ticketForm.TicketType === 'Issue / Error' ?
                    <Container maxwidth="xs">

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="issueCategory"
                                    label="Category"
                                    name="issueCategory"
                                    value={ticketForm.issueCategory}
                                    onChange={handleChange}   >
                                    {issueCategory.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Platform"
                                    label="Platform"
                                    name="Platform"
                                    value={ticketForm.Platform}
                                    onChange={handleChange}   >
                                    {platform.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {/* if Nav selected display field to paste the error */}
                            {ticketForm.Platform === 'Nav' ?
                                <Grid container item xs={12} sm={10} spacing={1}>
                                    <TextField
                                        name="issueNavErrorNo"
                                        fullWidth
                                        multiline
                                        rows="4"
                                        label="Paste Nav error here..."
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={ticketForm.issueNavErrorNo}
                                        inputProps={{ maxLength: 500 }}
                                    /></Grid> : null}

                            {ticketForm.Platform === 'More then one selected:' ?
                                <Grid container item xs={12} sm={10} spacing={1}>
                                    <div className={classes.groupSelect}>
                                        <FormGroup>
                                            <Grid container direction='row'>

                                                {/* <FormControlLabel control={<Checkbox checked={state.checkedB} onChange={handleChangeChecked} name="checkedB" color="primary" />} label="Primary" /> */}
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Returntek} name='Returntek' onChange={handleChangeChecked} color='primary' />} label="Returntek" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.VirtuRL} name='VirtuRL' onChange={handleChangeChecked} color='primary' />} label="VirtuRL" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.DataDEAD} name='DataDEAD' onChange={handleChangeChecked} color='primary' />} label="DataDEAD" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.TotalRMA} name='TotalRMA' onChange={handleChangeChecked} color='primary' />} label="TotalRMA" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Nav} name='Nav' onChange={handleChangeChecked} color='primary' />} label="Nav" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.WisetekTools} name='WisetekTools' onChange={handleChangeChecked} color='primary' />} label="Wisetek Tools" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.EmailOutlook} name='EmailOutlook' onChange={handleChangeChecked} color='primary' />} label="Email-Outlook" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Phone} name='Phone' onChange={handleChangeChecked} color='primary' />} label="Phone" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Website} name='Website' onChange={handleChangeChecked} color='primary' />} label="Website" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Fileserver} name='Fileserver' onChange={handleChangeChecked} color='primary' />} label="Fileserver" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.McAfee} name='McAfee' onChange={handleChangeChecked} color='primary' />} label="McAfee" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Jet} name='Jet' onChange={handleChangeChecked} color='primary' />} label="Jet" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Hardware} name='Hardware' onChange={handleChangeChecked} color='primary' />} label="Hardware" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.ARCHIE} name='ARCHIE' onChange={handleChangeChecked} color='primary' />} label="ARCHIE" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Domain} name='Domain' onChange={handleChangeChecked} color='primary' />} label="Domain" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.VPN} name='VPN' onChange={handleChangeChecked} color='primary' />} label="VPN" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.ISOXpress} name='ISOXpress' onChange={handleChangeChecked} color='primary' />} label="ISOXpress" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Network} name='Network' onChange={handleChangeChecked} color='primary' />} label="Network" />
                                            </Grid>
                                        </FormGroup>
                                    </div>
                                </Grid>
                                : null}

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Site"
                                    label="Site"
                                    name="Site"
                                    value={ticketForm.Site}
                                    onChange={handleChange}   >
                                    {site.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Department"
                                    label="Department"
                                    name="Department"
                                    value={ticketForm.Department}
                                    onChange={handleChange}   >
                                    {department.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Process"
                                    label="Division" // changed from Process Division
                                    name="Process"
                                    value={ticketForm.Process}
                                    onChange={handleChange}   >
                                    {process.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="issueArea"
                                    label="Process" // changed from Area to Process
                                    name="issueArea"
                                    value={ticketForm.issueArea}
                                    onChange={handleChange}   >
                                    {issueArea.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    name="issueDescription"
                                    fullWidth
                                    // required
                                    multiline
                                    rows="4"
                                    label="Issue Description"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={ticketForm.issueDescription}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            disabled={isLoadingAttachment}
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary">
                            Create ticket & send email
                        </Button>

                        <div className={classes.filePicker}>
                            <input
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                multiple
                                type="file"
                                onClick={disableSubmitBtn}
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Files
                                </Button>
                            </label>
                            <label htmlFor="remove-contained-button-file">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={handleChangeFilePickerRemove}>
                                    Remove Files
                                </Button>
                            </label>
                            {isLoadingAttachment ? <Loading /> : null}
                            {ticketForm.files.length === 0 ? null : <p>Files attached: {ticketForm.files.length}</p>}
                        </div>

                    </Container> : null}

                {ticketForm.TicketType === 'Change Request' ?
                    <Container maxwidth="xs">

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="requestCategory"
                                    label="Category"
                                    name="requestCategory"
                                    value={ticketForm.requestCategory}
                                    onChange={handleChange}   >
                                    {requestCategory.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Platform"
                                    label="Platform"
                                    name="Platform"
                                    value={ticketForm.Platform}
                                    onChange={handleChange}   >
                                    {platform.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            {ticketForm.Platform === 'More then one selected:' ?
                                <Grid container item xs={12} sm={10} spacing={1}>
                                    <div className={classes.groupSelect}>
                                        <FormGroup>
                                            <Grid container direction='row'>

                                                {/* <FormControlLabel control={<Checkbox checked={state.checkedB} onChange={handleChangeChecked} name="checkedB" color="primary" />} label="Primary" /> */}
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Returntek} name='Returntek' onChange={handleChangeChecked} color='primary' />} label="Returntek" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.VirtuRL} name='VirtuRL' onChange={handleChangeChecked} color='primary' />} label="VirtuRL" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.DataDEAD} name='DataDEAD' onChange={handleChangeChecked} color='primary' />} label="DataDEAD" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.TotalRMA} name='TotalRMA' onChange={handleChangeChecked} color='primary' />} label="TotalRMA" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Nav} name='Nav' onChange={handleChangeChecked} color='primary' />} label="Nav" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.WisetekTools} name='WisetekTools' onChange={handleChangeChecked} color='primary' />} label="Wisetek Tools" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.EmailOutlook} name='EmailOutlook' onChange={handleChangeChecked} color='primary' />} label="Email-Outlook" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Phone} name='Phone' onChange={handleChangeChecked} color='primary' />} label="Phone" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Website} name='Website' onChange={handleChangeChecked} color='primary' />} label="Website" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Fileserver} name='Fileserver' onChange={handleChangeChecked} color='primary' />} label="Fileserver" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.McAfee} name='McAfee' onChange={handleChangeChecked} color='primary' />} label="McAfee" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Jet} name='Jet' onChange={handleChangeChecked} color='primary' />} label="Jet" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Hardware} name='Hardware' onChange={handleChangeChecked} color='primary' />} label="Hardware" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.ARCHIE} name='ARCHIE' onChange={handleChangeChecked} color='primary' />} label="ARCHIE" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Domain} name='Domain' onChange={handleChangeChecked} color='primary' />} label="Domain" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.VPN} name='VPN' onChange={handleChangeChecked} color='primary' />} label="VPN" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.ISOXpress} name='ISOXpress' onChange={handleChangeChecked} color='primary' />} label="ISOXpress" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.Network} name='Network' onChange={handleChangeChecked} color='primary' />} label="Network" />
                                            </Grid>
                                        </FormGroup>
                                    </div>
                                </Grid>
                                : null}

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Site"
                                    label="Site"
                                    name="Site"
                                    value={ticketForm.Site}
                                    onChange={handleChange}   >
                                    {site.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Department"
                                    label="Department"
                                    name="Department"
                                    value={ticketForm.Department}
                                    onChange={handleChange}   >
                                    {department.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Process"
                                    label="Process"
                                    name="Process"
                                    value={ticketForm.Process}
                                    onChange={handleChange}   >
                                    {process.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                {/* for balance on the form */}
                            </Grid>
                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    name="requestDescription"
                                    multiline
                                    fullWidth
                                    // required
                                    rows="4"
                                    label="Request Description"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={ticketForm.requestDescription}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            disabled={isLoadingAttachment}
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary">
                            Create ticket & send email
                        </Button>

                        <div className={classes.filePicker}>
                            <input
                                // accept="image/*"
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                multiple
                                type="file"
                                onClick={disableSubmitBtn}
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Files
                                </Button>
                            </label>
                            <label htmlFor="remove-contained-button-file">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={handleChangeFilePickerRemove}>
                                    Remove Files
                                </Button>
                            </label>
                            {isLoadingAttachment ? <Loading /> : null}
                            {ticketForm.files.length === 0 ? null : <p>Files attached: {ticketForm.files.length}</p>}
                        </div>

                    </Container> : null}

            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}

export default TicketFormIT
