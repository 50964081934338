import React from 'react';
import { makeStyles } from '@material-ui/core';
import blackFridaySale2024 from '../../../../images/BlackFridaySale2024.jpg'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaVideoSlide: {
        width: '100%',
        borderRadius: 20,
    },
    media: {
        width: '60%',
        borderRadius: 20,
    },
}));

export default function BlackFridaySale2024(props) {
    const classes = useStyles();

    function goToShop() {
        window.open("https://wisetekstore.com/", "_blank", "noopener,noreferrer");
    }

    return (
        <div className={classes.root}>

            <div className={classes.textCentered} onClick={goToShop}>
                <img className={classes.media} src={blackFridaySale2024} alt="Black Friday Sale 2024" />
            </div>
            <br />

            <p>Regards,<br />
                Eleanor Lester<br />
                HR Co-Ordinator | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}